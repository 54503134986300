import videojs from "video.js"
import "videojs-youtube"

document.addEventListener("DOMContentLoaded", () => {

  if (window.jQuery) {

    const $videoList = $("#videoList");
    const $mainVideo = $("#mainVideo");

    if ($videoList.length && $mainVideo.length) {

      $videoList.find("a").on("click", e => {
        e.preventDefault();

        const player = videojs('mainVideo');
        const $mainVideoTitle = $("#mainVideoTitle");

        const url = e.target.href;
        const title = e.target.dataset.title;
        const poster = e.target.dataset.poster;

        $videoList.find("a").each(function () {
          $(this).parents(".media").removeClass("active");
        });
        $(e.target).parents(".media").addClass("active");

        player.poster(poster);
        player.src({
          type: 'video/youtube',
          src: url
        });
        // player.play();

        $mainVideoTitle.text(title);

      });
    }

  } else {
    console.error("jQuery not loaded!");
  }

});



